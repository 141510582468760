import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Column = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;

  @media (min-width: 1024px) {
    flex-basis: 50%;
    /* display: block; */
    align-items: flex-start;
    justify-content: ${({ center }) => (center ? 'center' : '')};
    margin-bottom: 40px;
  }
`;

export const TwoCol = ({ children, centerVertically, ...props }) => (
  <Column {...props} center={centerVertically}>
    {children}
  </Column>
);

TwoCol.propTypes = {
  reverse: PropTypes.bool,
};
