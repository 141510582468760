import styled from 'styled-components';

export const SectionSubtitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: ${({ theme, inverted }) =>
    inverted ? theme.color.white : theme.color.blue2};
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing.xxl};
  max-width: 23em;
`;
