import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const IconBubble = styled.div`
  --bubble-size: 32px;
  --icon-size: 16px;

  z-index: 2;
  position: absolute;
  bottom: 30px;
  right: 30px;
  /* transform: translate(-50%, -50%); */
  cursor: pointer;

  width: var(--bubble-size);
  height: var(--bubble-size);
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, 0.5);
  transition: opacity ease-in-out 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.5;
  }
`;

const breathing = keyframes`
 0%{
  transform: scale(0);
 }
 60%{
  transform: scale(1);
 }
`;

const animationFunc = css`
  animation: 4s ${breathing} alternate ease-out infinite;
`;

const IconBubbleBreathing = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: var(--bubble-size);
  height: var(--bubble-size);
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, 0.4);
  z-index: 0;
  transform-origin: center;

  ${({ isAnimating }) => (isAnimating ? animationFunc : '')};
`;

const IconSVG = styled.svg`
  z-index: 1;
  width: var(--icon-size);
  height: var(--icon-size);
  margin-left: 3px;
  stroke: var(--accent);
  fill: var(--accent);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const BreathingPlayButton = ({ animating, ...props }) => {
  return (
    <IconBubble {...props}>
      <IconSVG>
        <use href={`/icons/feather-sprite.svg#play`} />
      </IconSVG>
      <IconBubbleBreathing isAnimating={animating} />
    </IconBubble>
  );
};

export default BreathingPlayButton;
