import React from 'react';
import styled from 'styled-components';

//Component Imports

const SectionVideoContainer = styled.div`
  /* background: red; */
  z-index: 1;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;

  padding-top: 56px;

  @media (min-width: 1024px) {
    padding-top: 0;
    padding-right: ${({ position }) => (position == 'left' ? '104px' : '0')};
    padding-left: ${({ position }) => (position == 'right' ? '104px' : '0')};
    width: 50vw;
    max-width: none;
  }
`;

const SectionVideo = styled.video`
  max-width: 100%;
  display: block;
  border-radius: 8px;

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
    border-radius: ${({ position }) =>
      position == 'left' ? '0 8px 8px 0' : '8px 0 0 8px'};
  }
`;

const RocketLogo = styled.div`
  position: absolute;
  bottom: -30%;
  right: 5%;
  width: clamp(120px, 5.18vw + 100.58px, 200px);

  svg {
    width: 100%;
    height: 100%;
  }
`;

const HalfScreenVideo = ({ position }) => {
  return (
    <SectionVideoContainer position={position}>
      <SectionVideo
        autoPlay
        muted
        loop
        playsInline
        preload='metadata'
        poster={'videos/ROCKET_MIDPAGE_POSTER.jpg'}
        position={position}
      >
        <source src={'videos/ROCKET_MIDPAGE REEL_V1.webm'} type='video/webm' />
        <source src={'videos/ROCKET_MIDPAGE REEL_V1.mp4'} type='video/mp4' />
      </SectionVideo>
      <RocketLogo>
        <svg
          viewBox='0 0 176 299'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g>
            <path
              d='M0 299L48.81 169.178H2.58254V0H176V299H115.181V169.178H108.768L60.3884 299H0ZM115.181 53.5651H63.3583V116.517H115.181V53.5651Z'
              fill='white'
            />
          </g>
        </svg>
      </RocketLogo>
    </SectionVideoContainer>
  );
};

export default HalfScreenVideo;
