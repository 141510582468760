import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

//Component Imports
import { ButtonText } from '../theme/typography';

const HoverArrow = styled.svg`
  --arrowSpacing: 5px;
  --arrowHoverOffset: translateX(3px);
  position: relative;
  top: 1px;
  margin-left: var(--arrowSpacing);
  stroke-width: 2px;
  fill: none;
  stroke: currentColor;
  vertical-align: baseline;
  margin-bottom: 1px;
`;

const ArrowLinePath = styled.path`
  opacity: 0;
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const ArrowTipPath = styled.path`
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const handleButtonType = (type) => {
  switch (type) {
    case 'primary':
      return `color: var(--white); background: var(--accent);`;
    case 'secondary':
      return ` color: var(--accent); background: var(--white);`;
    default:
      return `color: var(--white); background: var(--accent);`;
  }
};

// TODO: Font styling is hard coded until new design system type is implemented
const CTAButton = styled(ButtonText)`
  padding-left: 16px;
  padding-right: ${({ arrow }) => (arrow ? '12px' : '16px')};
  white-space: nowrap;
  transition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-property: background-color;

  display: inline-block;
  padding-top: 5px;
  padding-bottom: 3px;
  border-radius: 16.5px;
  cursor: pointer;

  &:hover {
    opacity: ${({ type }) => (type === 'light' ? 1 : 0.9)};

    ${ArrowLinePath} {
      opacity: 1;
    }

    ${ArrowTipPath} {
      transform: var(--arrowHoverOffset);
    }
  }

  ${({ type, theme }) => handleButtonType(type, theme)};
`;

const CTAButtonLabel = styled.span``;

const Button = ({ label, path, arrow, type, ...props }) => {
  return (
    <CTAButton
      arrow={arrow ? 1 : 0}
      type={type}
      as={path ? Link : 'button'}
      to={path ? path : ''}
      {...props}
    >
      <CTAButtonLabel>{label}</CTAButtonLabel>
      {arrow && (
        <>
          &nbsp;
          <HoverArrow
            width='10'
            height='10'
            viewBox='0 0 10 10'
            aria-hidden='true'
          >
            <g fillRule='evenodd'>
              <ArrowLinePath
                className='HoverArrow__linePath'
                d='M0 5h7'
              ></ArrowLinePath>
              <ArrowTipPath
                className='HoverArrow__tipPath'
                d='M1 1l4 4-4 4'
              ></ArrowTipPath>
            </g>
          </HoverArrow>
        </>
      )}
    </CTAButton>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  arrow: PropTypes.bool,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'light',
    'link',
    'linkAccent',
    'submit',
  ]),
};

Button.defaultProps = {
  arrow: false,
  type: 'primary',
};

export default Button;
