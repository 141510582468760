import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ModalVideo from 'react-modal-video';

//Component Imports
import { P1, D2, PreHeader } from '../theme/typography';
import Button from '../components/buttons';

//Asset Imports

//Styled Components
const TextBlock = styled.div`
  color: var(--primary-text);
  max-width: 450px;
`;

const SecondaryHeader = styled(PreHeader)`
  margin-bottom: 4px;
  color: var(--white);
  font-family: 'Nielot';
`;

const PrimaryHeader = styled(D2)`
  margin-bottom: 16px;
  text-transform: capitalize;
  color: var(--white);
  text-wrap: balance;
  font-family: 'Nielot';
  text-transform: uppercase;
`;

const BodyText = styled(P1)`
  margin-bottom: ${({ hasButtons }) => (hasButtons ? '24px' : '0')};
  color: var(--primary-text);
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const ModalWrapper = styled.div`
  /* Take it out of document flow */
  position: absolute;
  /* Just to overwrite the modal video plugins styles */
  .modal-video-movie-wrap {
    background: none;
  }

  .modal-video {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modal-video-close-btn {
    cursor: pointer;
  }
`;

const PageTextBlock = ({ data, ...props }) => {
  const {
    title,
    preHeader,
    body,
    ctaButton,
    ctaButtonData,
    ctaLink,
    ctaLinkData,
  } = data;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = useCallback(
    () => setIsModalOpen((prevState) => !prevState),
    [],
  );

  return (
    <TextBlock {...props}>
      <SecondaryHeader>{preHeader}</SecondaryHeader>
      <PrimaryHeader>{title}</PrimaryHeader>

      <BodyText hasButtons={ctaButton || ctaLink}>{body}</BodyText>

      <ButtonContainer>
        {ctaButton && (
          <Button
            label={ctaButtonData.label}
            type={ctaButtonData.type ? ctaButtonData.type : 'primary'}
            arrow={ctaButtonData.arrow ? true : false}
            path={ctaButtonData.path ? ctaButtonData.path : '/'}
          />
        )}
        {ctaLink && !ctaLinkData.videoModal && (
          <Button
            label={ctaLinkData.label}
            type={ctaLinkData.type ? ctaLinkData.type : 'link'}
            arrow={ctaLinkData.arrow ? true : false}
            path={ctaLinkData.path ? ctaLinkData.path : '/'}
          />
        )}
        {ctaLink && ctaLinkData.videoModal && (
          <>
            <Button
              label={ctaLinkData.label}
              type={ctaLinkData.type ? ctaLinkData.type : 'link'}
              arrow={ctaLinkData.arrow ? true : false}
              onClick={toggleModal}
            />
            <ModalWrapper>
              <ModalVideo
                channel='vimeo'
                isOpen={isModalOpen}
                videoId={ctaLinkData.vimeoID}
                onClose={toggleModal}
              />
            </ModalWrapper>
          </>
        )}
      </ButtonContainer>
    </TextBlock>
  );
};

PageTextBlock.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.oneOf(['primary', 'ticks', 'twoSubPoint']),
    title: PropTypes.string.isRequired,
    preHeader: PropTypes.string.isRequired,
    body: PropTypes.string,
    ctaButton: PropTypes.bool,
    ctaButtonType: PropTypes.string,
    ctaLink: PropTypes.bool,
    testimonial: PropTypes.bool,
  }),
};

PageTextBlock.defaultProps = {
  data: {
    type: 'primary',
    ctaButton: false,
    ctaLink: false,
    testimonial: false,
  },
};

export default PageTextBlock;
