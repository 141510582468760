// Extracts Vimeo video ID from URL
export default function getVimeoID(videoUrl) {
  const regExp = 'vimeo\\.com/(?:.*#|.*/videos/)?([0-9]+)';
  if (videoUrl.match(regExp)) {
    return videoUrl.split('/')[3].split('?')[0];
  }
  return null;
}

export function getVimeoUnlistedHash(videoUrl) {
  const regExp = 'vimeo\\.com/(?:.*#|.*/videos/)?([0-9]+)';
  if (videoUrl.match(regExp) && videoUrl.split('/').length > 4) {
    return videoUrl.split('/')[4].split('?')[0];
  }
  return null;
}
