import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Component Imports
import { PreHeader, D2, P1 } from '../theme/typography';
import Button from './buttons';

const BlockContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 900px;
`;

const SecondaryHeader = styled(PreHeader)`
  margin-bottom: 4px;
  color: var(--white);
`;

const PrimaryHeader = styled(D2)`
  margin-bottom: ${({ hasContentBelow }) => (hasContentBelow ? '24px' : '0')};
  text-transform: uppercase;
  color: var(--primary-text);
  text-wrap: balance;
`;

const BodyText = styled(P1)`
  margin-bottom: ${({ hasButtons }) => (hasButtons ? '32px' : '0')};
  color: var(--primary-text);
  max-width: 620px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`;

const CenteredTextBlock = ({ data }) => {
  const {
    preHeader,
    title,
    body,
    primaryCTA,
    primaryCTAData,
    secondaryCTA,
    secondaryCTAData,
  } = data;

  return (
    <BlockContainer>
      <SecondaryHeader>{preHeader}</SecondaryHeader>
      <PrimaryHeader
        hasContentBelow={primaryCTA || secondaryCTA || body != null}
      >
        {title}
      </PrimaryHeader>
      <BodyText hasButtons={primaryCTA || secondaryCTA}>{body}</BodyText>
      <ButtonContainer>
        {primaryCTA && (
          <Button
            label={primaryCTAData.label}
            type={primaryCTAData.type ? primaryCTAData.type : 'primary'}
            arrow={primaryCTAData.arrow ? true : false}
            path={primaryCTAData.path}
          />
        )}
        {secondaryCTA && (
          <Button
            label={secondaryCTAData.label}
            type={secondaryCTAData.type ? secondaryCTAData.type : 'link'}
            arrow={secondaryCTAData.arrow ? true : false}
            path={secondaryCTAData.path}
          />
        )}
      </ButtonContainer>
    </BlockContainer>
  );
};

CenteredTextBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    preHeader: PropTypes.string.isRequired,
    body: PropTypes.string,
    primaryCTA: PropTypes.bool,
    primaryCTAData: PropTypes.object,
    secondaryCTA: PropTypes.bool,
    secondaryCTAData: PropTypes.object,
  }),
};

export default CenteredTextBlock;
