import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const CutWrapper = styled.div`
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 100%;
  /* Setting height by viewport width maintains angle of slope on screen resize */
  height: 8vw;

  > svg {
    fill: ${(props) =>
      (props.light && 'var(--white)') ||
      (props.dark && 'var(--accent)') ||
      'var(--white)'};
  }
`;

const BottomCut = ({ light, dark, ...props }) => (
  <CutWrapper light={light} dark={dark} {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
      width='100%'
      height='100%'
    >
      <polygon points='0,100 100,0 100,100' />
    </svg>
  </CutWrapper>
);

BottomCut.propTypes = {
  light: PropTypes.bool,
  dark: PropTypes.bool,
};
export default BottomCut;
