import React, { useState } from 'react';
import styled from 'styled-components';
import ModalVideo from 'react-modal-video';
import { StaticImage } from 'gatsby-plugin-image';

import { Section } from '../components/page-elements';
import Container from '../components/container';
import Spacer from '../components/spacer';
import Button from '../components/buttons';
import BottomCut from '../components/bottomCut';
import { TwoCols, TwoCol } from '../components/page-elements';
import PageTextBlock from '../components/pageTextBlock';
import HalfScreenVideo from '../components/halfScreenVideo';
import CenteredTextBlock from '../components/centeredTextBlock';

import getVimeoID, { getVimeoUnlistedHash } from '../helpers/getVimeoID';
import BreathingPlayButton from '../components/breathingPlayButton';

const HeaderWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 32px;
  gap: 32px;
`;

const SuperHeader = styled.h1`
  font-family: Nielot;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-SH);
  line-height: 64px;
  line-height: 90%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  text-align: center;
  color: var(--accent);

  @media (min-width: 750px) {
    /* line-height: 88px; */
    letter-spacing: -0.01em;
  }

  @media (min-width: 1200px) {
    /* line-height: 104px; */
  }
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalWrapper = styled.div`
  /* Take it out of document flow */
  position: absolute;
  /* Just to overwrite the modal video plugins styles */
  .modal-video-movie-wrap {
    background: none;
  }

  .modal-video {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modal-video-close-btn {
    cursor: pointer;
  }
`;

const PosterImage = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (hover: hover) {
    transition: transform 0.4s ease;
  }

  > img {
    border-radius: var(--border-radius);
  }
`;

const VideoThumbWrapper = styled.div`
  --border-radius: 8px;

  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  cursor: pointer;
  background: red;
  width: clamp(350px, 33.8vw + 223.24px, 710px);
  aspect-ratio: 16 / 9;
  max-width: 100%;

  @media (hover: hover) {
    &:hover {
      ${PosterImage} {
        transform: scale(1.05);
      }
    }
  }
`;

const ContactCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 800px) {
    align-items: flex-start;
  }
`;

const HeadshotWrap = styled.div`
  position: relative;
  width: 132px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  overflow: clip;
  margin-bottom: 16px;
`;

const Name = styled.h3`
  font-family: Nielot;
  line-height: 100%;
  letter-spacing: 0em;
  color: var(--white);
  font-size: 36px;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const Title = styled.h4`
  font-family: Nielot;
  line-height: 100%;
  letter-spacing: 0em;
  color: var(--white);
  font-size: 20px;
  margin-bottom: 32px;
  text-transform: uppercase;
`;

const ContactDetailWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  /* border: 1px solid blue; */
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.5;
  }
`;

const ContactIcon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  transform: translateY(-1px);
`;

const ContactDetail = styled.div`
  font-family: Proxima Nova;
  color: var(--white);
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--font-weight-light);
`;

const RocketLogo = styled.div`
  width: 80px;
  margin: 0 auto;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const People = [
  {
    name: 'Eoghan Ryan',
    title: 'New Business',
    mobile: '+353 (86) 856 5530',
    email: 'eoghan@rubberduck.ie',
    headshot: '../images/eoghan.jpg',
  },
  {
    name: 'Helen Barry Duke',
    title: 'Producer',
    mobile: '+353 (87) 065 9961',
    email: 'helen@rubberduck.ie',
    headshot: '../images/helen.jpg',
  },
  {
    name: 'Jenni Barry Duke',
    title: 'Producer',
    mobile: '+353 (86) 241 9213',
    email: 'jenni@rubberduck.ie',
    headshot: '../images/jenni.jpg',
  },
];
const IndexPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const VimeoLink = 'https://vimeo.com/912380207/24bd1ac7a9';

  return (
    <>
      <Container>
        <HeaderWrap>
          <Button
            type='primary'
            label='To Rubberduck'
            arrow
            path={'https://www.rubberduck.ie'}
          />
          <RocketLogo style={{ width: '32px', margin: 0 }}>
            <svg
              viewBox='0 0 176 299'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g>
                <path
                  d='M0 299L48.81 169.178H2.58254V0H176V299H115.181V169.178H108.768L60.3884 299H0ZM115.181 53.5651H63.3583V116.517H115.181V53.5651Z'
                  fill='#c41f53'
                />
              </g>
            </svg>
          </RocketLogo>
        </HeaderWrap>
      </Container>
      <Section cuts='bottomCut'>
        <Container>
          <Center>
            <SuperHeader>
              It's not <br />
              Яocket science
            </SuperHeader>
            <Spacer size={64} />
            <VideoThumbWrapper onClick={() => setIsModalOpen(true)}>
              <PosterImage>
                <StaticImage src='../images/poster.jpg' />
              </PosterImage>
              <BreathingPlayButton animating={true} />
            </VideoThumbWrapper>
            <ModalWrapper>
              <ModalVideo
                channel='vimeo'
                isOpen={isModalOpen}
                videoId={getVimeoID(VimeoLink)}
                onClose={() => setIsModalOpen(false)}
                ratio='16:9'
                vimeo={{
                  h: getVimeoUnlistedHash(VimeoLink),
                  autoplay: true,
                  autopause: false,
                }}
              />
            </ModalWrapper>
            <Spacer size={48} />
            <Button
              type='primary'
              label='Check out our new home'
              arrow
              path={'https://www.rubberduck.ie'}
            />
          </Center>
        </Container>
        <BottomCut dark />
      </Section>
      <Section dark cuts='topCut' style={{ overflowX: 'clip' }}>
        <Container>
          <TwoCols>
            <TwoCol>
              <PageTextBlock
                data={{
                  title: `Production has changed, so have we.`,
                  preHeader: `We've moved`,
                  body: `We are thrilled to announce the merger of Rocket Science into Rubberduck, marking a significant milestone in our journey. Building on the phenomenal success of Rubberduck over the past few years, this strategic collaboration aims to propel our growth to new heights. By combining the unique strengths and expertise of both companies, we are set to turbocharge our capabilities and provide an unparalleled end-to-end video production offering for projects of all sizes. `,
                  ctaButton: true,
                  ctaButtonData: {
                    type: 'secondary',
                    label: 'take me home, country roads',
                    arrow: true,
                    path: 'https://www.rubberduck.ie',
                  },
                  ctaLink: false,
                  ctaLinkData: {},
                }}
              />
            </TwoCol>
            <TwoCol>
              <HalfScreenVideo position={'right'} />
            </TwoCol>
          </TwoCols>
        </Container>
      </Section>
      <Section dark cuts='topAndBottomCut'>
        <Container>
          <CenteredTextBlock
            data={{
              preHeader: `Contact Details`,
              title: `New home, same rock-solid team`,
              body: `We’re all buzzing with the news, so feel free to give us a bell and we’ll talk the ear of you with all the juicy details.`,
              primaryCTA: false,
              primaryCTAData: {},
              secondaryCTA: false,
              secondaryCTAData: {},
            }}
          />
          <Spacer size={64} />
          <ContactCards>
            {People.map((person, index) => (
              <ContactCard>
                <HeadshotWrap>
                  {
                    {
                      'Eoghan Ryan': (
                        <StaticImage
                          src={`../images/eoghan.jpg`}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      ),
                      'Helen Barry Duke': (
                        <StaticImage
                          src={`../images/helen.jpg`}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      ),
                      'Jenni Barry Duke': (
                        <StaticImage
                          src={`../images/jenni.jpg`}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      ),
                    }[person.name]
                  }
                </HeadshotWrap>
                <Name>{person.name}</Name>
                <Title>{person.title}</Title>
                <a href={`tel:${person.mobile}`}>
                  <ContactDetailWrap style={{ marginBottom: '8px' }}>
                    <ContactIcon>
                      <svg
                        viewBox='0 0 20 21'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M18.3333 14.9529V17.4529C18.3343 17.685 18.2867 17.9148 18.1937 18.1274C18.1008 18.34 17.9644 18.5309 17.7934 18.6878C17.6224 18.8447 17.4205 18.9642 17.2006 19.0385C16.9808 19.1129 16.7478 19.1405 16.5167 19.1196C13.9523 18.841 11.4892 17.9647 9.32498 16.5613C7.31151 15.2818 5.60443 13.5748 4.32499 11.5613C2.91663 9.38728 2.04019 6.91211 1.76665 4.33628C1.74583 4.10583 1.77321 3.87358 1.84707 3.6543C1.92092 3.43502 2.03963 3.23352 2.19562 3.06263C2.35162 2.89174 2.54149 2.7552 2.75314 2.66171C2.9648 2.56822 3.1936 2.51983 3.42499 2.51961H5.92499C6.32941 2.51563 6.72148 2.65884 7.02812 2.92256C7.33476 3.18627 7.53505 3.55248 7.59165 3.95295C7.69717 4.753 7.89286 5.53855 8.17499 6.29461C8.2871 6.59288 8.31137 6.91704 8.24491 7.22868C8.17844 7.54032 8.02404 7.82637 7.79998 8.05295L6.74165 9.11128C7.92795 11.1976 9.65536 12.925 11.7417 14.1113L12.8 13.0529C13.0266 12.8289 13.3126 12.6745 13.6243 12.608C13.9359 12.5416 14.26 12.5658 14.5583 12.6779C15.3144 12.9601 16.0999 13.1558 16.9 13.2613C17.3048 13.3184 17.6745 13.5223 17.9388 13.8342C18.203 14.1461 18.3435 14.5443 18.3333 14.9529Z'
                          stroke='white'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    </ContactIcon>
                    <ContactDetail>{person.mobile}</ContactDetail>
                  </ContactDetailWrap>
                </a>
                <a href={`mailto:${person.email}`}>
                  <ContactDetailWrap>
                    <ContactIcon>
                      <svg
                        viewBox='0 0 20 21'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M18.3333 5.85319C18.3333 4.93652 17.5833 4.18652 16.6667 4.18652H3.33332C2.41666 4.18652 1.66666 4.93652 1.66666 5.85319M18.3333 5.85319V15.8532C18.3333 16.7699 17.5833 17.5199 16.6667 17.5199H3.33332C2.41666 17.5199 1.66666 16.7699 1.66666 15.8532V5.85319M18.3333 5.85319L9.99999 11.6865L1.66666 5.85319'
                          stroke='white'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    </ContactIcon>
                    <ContactDetail>{person.email}</ContactDetail>
                  </ContactDetailWrap>
                </a>
              </ContactCard>
            ))}
          </ContactCards>
          <Spacer size={48} />
        </Container>
        <BottomCut light />
      </Section>
      <Section light cuts='topCut'>
        <RocketLogo>
          <svg
            viewBox='0 0 176 299'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g>
              <path
                d='M0 299L48.81 169.178H2.58254V0H176V299H115.181V169.178H108.768L60.3884 299H0ZM115.181 53.5651H63.3583V116.517H115.181V53.5651Z'
                fill='#c41f53'
              />
            </g>
          </svg>
        </RocketLogo>
      </Section>
    </>
  );
};

export default IndexPage;
